const root = document.documentElement;
const userAgent = window.navigator.userAgent.toLowerCase();
const viewport = document.querySelector('meta[name=viewport]');

const _ua = (function (u) {
  // UA判別
  return {
    IE: u.indexOf('msie') != -1 || u.indexOf('trident') != -1,
    Android: u.indexOf('android') != -1,
    Tablet:
      (u.indexOf('windows') != -1 && u.indexOf('touch') != -1 && u.indexOf('tablet pc') == -1) ||
      u.indexOf('ipad') != -1 ||
      (u.indexOf('macintosh') > -1 && 'ontouchend' in document) ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') == -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('tablet') != -1) ||
      u.indexOf('kindle') != -1 ||
      u.indexOf('silk') != -1 ||
      u.indexOf('playbook') != -1,
    Mobile:
      (u.indexOf('windows') != -1 && u.indexOf('phone') != -1) ||
      u.indexOf('iphone') != -1 ||
      u.indexOf('ipod') != -1 ||
      (u.indexOf('android') != -1 && u.indexOf('mobile') != -1) ||
      (u.indexOf('firefox') != -1 && u.indexOf('mobile') != -1) ||
      u.indexOf('blackberry') != -1
  };
})(userAgent);

{
  // jsが有効の場合にhtmlのclassをno-jsからjsに変える
  root.className = root.className.replace(/\bno[-_]?js\b/gi, '') + ' js';
  // Androidの時はhtmlにclass追加 (is-android)
  if (_ua.Android) {
    root.classList.add('is-android');
  }
  // IEの時はhtmlにclass追加 (is-IE)
  if (_ua.IE) {
    root.classList.add('is-IE');
  }
  // タブレットの時はhtmlにclass追加 (is-tablet)
  if (_ua.Tablet) {
    root.classList.add('is-tablet');
    // タブレットの時のviewportを上書き ※タブレット対応レスポンシブのときは不要
    // width=1140は、デザインにあわせて変更する
    viewport.setAttribute('content', 'width=1728');
  }
}

!(function () {
  function switchViewport() {
    const value =
      window.outerWidth > 375
        ? 'width=device-width, initial-scale=1, maximum-scale=1, shrink-to-fit=no'
        : 'width=375, maximum-scale=1';
    if (viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value);
    }
  }
  addEventListener('resize', switchViewport, false);
  if (!_ua.Tablet) {
    switchViewport();
  }
})();
